// stores/player.js
import { defineStore } from 'pinia';

export const usePlayerStore = defineStore('player', {
  state: () => ({
    isPlaying: false,
    audioSrc: 'https://listen.astra.fm/listen/astra/radio.mp3',

  }),
  actions: {
    play() {
      this.isPlaying = true;
    },
    stop() {
      this.isPlaying = false;
    },
  },
});