<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue';
import { usePlayerStore } from '@/stores/player'; // Ajusta la ruta según corresponda
import { fetchNowPlaying } from '@/services/api';
import { getCurrentShow } from '@/services/currentShow'; // Asegúrate de que esta ruta sea correcta


const store = usePlayerStore();
const audioPlayer = ref(null);
const nowPlaying = ref(null);
const stationId = '1'; // Asegúrate de que este es el valor correcto para tu caso
const currentShow = ref({}); // Añade esta línea para almacenar los datos del show actual
let intervalId = null;
const showDescription = ref(false);



// actualización periódica y limpieza de intérvalo

// Función para actualizar la información del show actual
function updateCurrentShow() {
  const showData = getCurrentShow(); // Asume que esta función devuelve los datos del show basados en la hora actual
  if (showData) {
    currentShow.value = showData;
  }
}

onMounted(async () => {
  updateCurrentShow(); // Llama a updateCurrentShow al montar
  nowPlaying.value = await fetchNowPlaying(stationId);
  intervalId = setInterval(async () => {
    updateCurrentShow(); // Asegúrate de actualizar el show actual junto con la canción actual
    nowPlaying.value = await fetchNowPlaying(stationId);
  }, 30000); // Actualiza cada 30 segundos
  /*if (audioPlayer.value) {
      store.setAudioPlayer(audioPlayer.value); // Guardar referencia
  }*/
});

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId);
  }
});

// Reacciona a cambios en el estado de reproducción
watch(() => store.isPlaying, (newVal) => {
  if (newVal) {
    playAudio();
  } else {
    stopAudio();
  }
});

function playAudio() {
  if (audioPlayer.value) {
    audioPlayer.value.src = store.audioSrc;
    audioPlayer.value.load();
    audioPlayer.value.play().catch(console.error);
  }
}

function stopAudio() {
  if (audioPlayer.value) {
    audioPlayer.value.pause();
    audioPlayer.value.src = ''; // Detiene el stream y limpia el recurso
  }
}

// Función para alternar la reproducción
function togglePlayback() {
  if (store.isPlaying) {
    store.stop();
  } else {
    store.play();
  }
}

</script>

<template>
  <div class="aa-controls">
    <audio ref="audioPlayer">
    Your browser does not support the audio element.
    </audio>
    <div class="aa-player-image">
      <img :src="currentShow.image" alt="Current Show">
    </div>
    <div class="aa-divider-container">
      <div class="aa-now-playing">
        <div class="show-and-status">
          <p class="aa-title-md">{{ currentShow.name }}</p>
          <div class="status-icon">
            <div class="circle"></div>
            <span class="label">En el aire</span>
          </div>
        </div>
          <div class="aa-caption">
          <span>{{ currentShow.subtitle }}</span> - <span>{{ currentShow.startTime }}:00 - {{ currentShow.endTime }}:00</span>
          </div>
          <span id="show-subtitle"></span>
      </div>
      <div class="aa-divider"></div>
    </div>
    <div class="aa-play-btn">
      <!-- Ícono de Play -->
      <button v-if="!store.isPlaying"><i @click="togglePlayback" class="fa-solid fa-play"></i></button>
      <!-- Ícono de Pause -->
      <button v-else @click="togglePlayback"><i class="fa-solid fa-pause"></i></button>
    </div>
    <div class="aa-plus d-none d-sm-block">
      <button @click="showDescription = !showDescription"><i class="aa-icon" :class="{'fa-solid fa-plus': !showDescription, 'fa-solid fa-minus': showDescription}"></i></button>
    </div>
    <div v-if="showDescription" class="aa-player-show-description">
      <div>{{ currentShow.description }}</div>
      <img :src="currentShow.image" alt="Current Show" class="mt-2">
    </div>
  </div>
  
</template>

<style lang="scss" scoped>
@import "@/assets/scss/astra-vars.scss";

// ----------- PLAYER -------------

// on air

.show-and-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.status-icon {
  display: flex;
  align-items: center;
  margin-left:10px;

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff6600;
  }

  .label {
    margin-left: 5px;
    font-size: 10px; // Ajusta entre 10px y 12px según necesidades
    @media (min-width: $layout-breakpoint-mediumxl) {
      font-size: 12px; // Ajusta para pantallas más grandes
    }
  }
}



// cierra on air

:root {
  --main-dark: #25272a;
  --neu-dark: #090a0c;
  --neu-light: #252a30;
  --back-gradient: linear-gradient(
    180deg,
    rgba(62, 67, 76, 1) 0%,
    rgba(30, 32, 36, 1) 100%
  );
}
.aa-controls{
    margin-top:$s08;
    margin-bottom:$s08;
    color: $font-color-primary;
    padding-left:$s04;
    padding-right:$s04;
    flex-wrap: wrap;
    display: flex; // Activa Flexbox
    align-items: center; // Alinea los elementos verticalmente en el centro
    justify-content: space-between; // Espacia los elementos equitativamente
    border-radius: $s08;
    background-color: $bg-alpha-primary-primary;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
    .aa-divider-container {
        // Ajustes específicos para aa-divider-container si son necesarios
        flex-grow: 1; // Permite que el contenedor crezca si es necesario
        padding:$s12;
        display: none;
    }
    .aa-play-btn, .aa-player-image, .aa-plus {
        padding:$s12;
    }
    .aa-play-btn, .aa-plus{
      .fas, .fa-solid{
        font-size: 24px;
        cursor:pointer;
      }
    }
    .aa-player-image{
      img{
        max-width:45px;
        border-radius: 100%;
      }
    }
    .aa-now-playing{
      p{
        margin:0;
      }
    }
    .aa-player-show-description {
      order: 2; /* Coloca este elemento al final */
      width: 100%; /* Ocupa el ancho completo */
      padding:$s14;
      position: absolute;
      top:55px;
      background-color: $bg-alpha-primary-primary;
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(15px);
      border-bottom-right-radius:$s08;
      border-bottom-left-radius:$s08;
      right:0.5px;
      img{
        width:100%;
        border-radius: $s08;
      }
    }
    @media (min-width: $layout-breakpoint-mediumxl) { 
      .aa-divider-container {
        display: block;
    }
  }/* Escritorios medianos y arriba */

}


</style>